import SplitType from 'split-type'

export class SplitWords extends HTMLElement {
    constructor() {
        super()

        new SplitType(this, {
            types: 'words',
            wordClass: 'single-word',
        })
    }

    connectedCallback() {
        Array.from(this.querySelectorAll('.single-word')).forEach((item, index) => {
            const wrapper = document.createElement('animate-element')
            wrapper.className = 'block'
            wrapper.dataset.animate = this.dataset.animate
            wrapper.dataset.animateDelay = (
                parseInt(this.dataset.animateDelay || '0') +
                index * 30
            ).toString()

            for (const itemContent of item.childNodes) {
                wrapper.appendChild(itemContent)
            }

            item.appendChild(wrapper)
        })
    }
}
