<template>
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.6" cx="50" cy="50" r="49" stroke="#FFD700" stroke-width="2" />
        <circle opacity="0.6" cx="50" cy="50" r="40" fill="#FFD700" />
        <path
            d="M64 48.2679C65.3333 49.0378 65.3333 50.9622 64 51.732L44.5 62.9904C43.1667 63.7602 41.5 62.7979 41.5 61.2583L41.5 38.7417C41.5 37.2021 43.1667 36.2398 44.5 37.0096L64 48.2679Z"
            fill="white" />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>