import { BlockModule } from './common'
import {
    addDotBtnsAndClickHandlers,
    setupTweenOpacity,
    addPrevNextBtnsClickHandlers,
} from './embla'
import EmblaCarousel from 'embla-carousel'
import './button/button.css'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { createApp, defineComponent, ref } from 'vue'
import { useEventListener } from '@vueuse/core'

new BlockModule({
    selector: '.block-investor-success-stories-section',
    setupBlock(el, onTeardown) {
        const emblaContainer = el.querySelector('.embla')
        const viewportNode = emblaContainer?.querySelector('.embla__viewport') as HTMLElement

        const prevBtn = emblaContainer?.querySelector('.embla__prev')
        const nextBtn = emblaContainer?.querySelector('.embla__next')

        const dotsNode = <HTMLElement>emblaContainer?.querySelector('.embla__dots')
        if (!emblaContainer) return

        const emblaApi = EmblaCarousel(viewportNode, { loop: true })

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(emblaApi, dotsNode)
        const removeTweenOpacity = setupTweenOpacity(emblaApi)
        const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
            emblaApi,
            prevBtn as HTMLElement,
            nextBtn as HTMLElement,
        )

        const slides = emblaApi.slideNodes()
        const togglePointerEvents = () => {
            const activeIndex = emblaApi.selectedScrollSnap()

            slides.forEach((slide, index) => {
                if (index !== activeIndex) {
                    slide.classList.add('pointer-events-none')
                } else {
                    slide.classList.remove('pointer-events-none')
                }
            })
        }

        togglePointerEvents()
        emblaApi.on('select', togglePointerEvents)

        const playerDialogEl = el.querySelector('.dialog-vue-app')
        const buttons = el.querySelectorAll('button[data-video-player-id]')

        if (playerDialogEl) {
            const dialogVueApp = createApp(
                defineComponent({
                    components: {
                        DialogPopUp: Dialog,
                        DialogContent,
                        DialogDescription,
                        DialogFooter,
                        DialogHeader,
                        DialogTitle,
                        DialogTrigger,
                        DialogClose,
                    },
                    setup() {
                        const activePlayerId = ref('')

                        buttons.forEach((el) => {
                            const videoPlayerId = el.getAttribute('data-video-player-id')

                            useEventListener(el, 'click', () => {
                                activePlayerId.value = videoPlayerId ?? ''
                            })
                        })

                        const onPlayerDialogModelValueUpdate = (isOpen: boolean) => {
                            if (!isOpen) {
                                activePlayerId.value = ''
                            }
                        }

                        return {
                            activePlayerId,
                            onPlayerDialogModelValueUpdate,
                        }
                    },
                }),
            )

            dialogVueApp.mount(playerDialogEl)

            onTeardown(() => {
                dialogVueApp.unmount()
            })
        }

        onTeardown(() => {
            removePrevNextBtnsClickHandlers()
            removeTweenOpacity()
            removeDotBtnsAndClickHandlers()
            emblaApi.off('select', togglePointerEvents)
            emblaApi.destroy()
        })
    },
})
