import { computedAsync, type AsyncComputedOptions } from "@vueuse/core";
import { unref, type MaybeRef } from "vue";
import type { ListingDataItem } from "./types";
import axios from "axios";
import type { APIResponse, PaginatedData } from "@/types";

export const createGenericListingComputedAsync = (
    listingId: MaybeRef<string>,
    tagIds: MaybeRef<string[]> = [],
    sorting: MaybeRef<string> = '0',
    pageNumber: MaybeRef<number> = 1,
    pageSize: MaybeRef<number> = 9,
    options?: AsyncComputedOptions
) => {
    return computedAsync<PaginatedData<ListingDataItem>|undefined>(
        onCancel => {
            const abortController = new AbortController();

            onCancel(() => abortController.abort());

            const targetListingId = unref(listingId)

            return axios.get<APIResponse<PaginatedData<ListingDataItem>>>(
                `/api/generic-listing/listing/${targetListingId}`,
                {
                    signal: abortController.signal,
                    params: {
                        tagIds: unref(tagIds).filter((x) => x !== ''),
                        sorting: unref(sorting),
                        pageNumber: unref(pageNumber),
                        pageSize: unref(pageSize),
                    }
                }
            ).then(response => response.data.data)
        },
        undefined,
        options
    )
}