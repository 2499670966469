import { BlockModule } from '../common'

interface UmbracoFormBlockData {
    submitButtonStyle: string
}

const loadScripts = async () => {
    import('./umbraco-forms.css')

    return Promise.all([import('./submit-buttons'), import('./phone-inputs')])
}

new BlockModule({
    selector: '.block-umbraco-form',
    async setupBlock(el) {
        const blockData = $(el).data() as UmbracoFormBlockData

        const $form = $(el).find('form')
        if ($form.length === 0) return

        const [{ processSubmitButtons }, { processPhoneInputs }] = await loadScripts()

        processSubmitButtons($form, blockData.submitButtonStyle)

        await processPhoneInputs($form)

        if ($.validator.unobtrusive) {
            $.validator.unobtrusive.parse(el)
        }

        document.dispatchEvent(new CustomEvent('umbracoForms.init'))
    },
})
