import { BlockModule } from './common'

new BlockModule({
    selector: '.block-upcoming-events-overview-section',
    // inViewDebugMode: true,
    async setupBlock(el, onTeardown, context) {
        const emblaContainer = el.querySelector('.embla')
        const dotsNode = <HTMLElement>emblaContainer?.querySelector('.embla__dots')
        if (!emblaContainer) return

        const [{ default: EmblaCarousel }, { addDotBtnsAndClickHandlers }, { gsap }] =
            await Promise.all([import('embla-carousel'), import('./embla'), import('gsap')])

        const emblaApi = EmblaCarousel(emblaContainer as HTMLElement, {
            align: 'center',
            breakpoints: {
                '(min-width: 1280px)': {
                    active: false,
                },
            },
        })

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(emblaApi, dotsNode)

        const slides = el.querySelectorAll('.embla__slide')

        const timeline = gsap.timeline({
            paused: true,
        })

        timeline.from(slides, {
            x: 100,
            stagger: 0.1,
            opacity: 0,
            duration: 0.5,
            ease: 'power2.out',
        })

        context.onInView(() => {
            timeline.play()
        })

        onTeardown(() => {
            removeDotBtnsAndClickHandlers()
            emblaApi.destroy()
            timeline.revert()
        })
    },
})
