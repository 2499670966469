import { defineComponent } from 'vue'
import { BlockModule } from '../common'
import { MainNavigation } from './components'
import { Accordion, AccordionContent, AccordionItem } from '@/components/ui/accordion'
import { cn } from '@/lib/utils'

import {
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'

import { AccordionTrigger } from '@/components/website/accordion'
import { useNavContext } from './composables'

new BlockModule({
    selector: '.bnm-main-navigation',
    vueComponent: defineComponent({
        components: {
            MainNavigation,
            RadixNavigationMenuItem: NavigationMenuItem,
            RadixNavigationMenuLink: NavigationMenuLink,
            RadixNavigationMenuTrigger: NavigationMenuTrigger,
            RadixNavigationMenuContent: NavigationMenuContent,
            // RadixNavigationMenuViewport,
            // RadixNavigationMenuRoot,
            Accordion,
            AccordionContent,
            AccordionTrigger,
            AccordionItem,
        },
        setup() {
            const { isSolidColorNav, isNavTransparent } = useNavContext()

            return {
                isSolidColorNav,
                isNavTransparent,
                cn,
            }
        },
    }),
})
