import type { EmblaCarouselType } from 'embla-carousel'

const tweenOpacity = (emblaApi: EmblaCarouselType): void => {
    const slideNodes = emblaApi.slideNodes()
    const selectedIndex = emblaApi.selectedScrollSnap()

    slideNodes.forEach((slide, index) => {
        const setOpacity = index === selectedIndex ? 1 : 0.15
        slide.style.transition = 'opacity 0.4s ease'
        slide.style.opacity = setOpacity.toString()
    })
}

export const setupTweenOpacity = (emblaApi: EmblaCarouselType) => {
    const slideNodes = emblaApi.slideNodes()

    tweenOpacity(emblaApi)

    emblaApi
        .on('reInit', tweenOpacity)
        .on('scroll', () => tweenOpacity(emblaApi))
        .on('select', () => tweenOpacity(emblaApi))

    return () => {
        slideNodes.forEach((slide) => slide.removeAttribute('style'))
    }
}
