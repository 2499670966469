import * as Sentry from '@sentry/vue'

const origin = window.location.origin

export const createSentry = () => {
    if (import.meta.env.DEV) return

    Sentry.init({
        dsn: 'https://5afc222e68b2d0e34a7ad992bebddadb@o4504790555623424.ingest.us.sentry.io/4507892722302976',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                networkDetailAllowUrls: [
                    'https://dev.investmy.bitlab.solutions',
                    'https://staging.investmy.bitlab.solutions',
                ],
            }),
        ],
        denyUrls: ['localhost'],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/dev.investmy.bitlab.solutions/,
            /^https:\/\/staging.investmy.bitlab.solutions/,
        ],
        environment: origin.includes('staging') ? 'staging' : 'dev',
        // Session Replay
        replaysSessionSampleRate: origin.includes('staging') ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}
