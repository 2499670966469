<template>
    <div
        :class="
            cn(
                'flex h-18 items-center transition-colors lg:h-[5.5rem]',
                isNavTransparent ? 'border-b border-b-background/70' : 'bg-primary',
                isSolidColorNav && 'border-primary bg-primary',
            )
        ">
        <div class="container flex items-center">
            <template v-if="!xlAndGreater">
                <slot name="brand"></slot>

                <div class="flex-grow"></div>

                <NavbarMobile>
                    <template #logo>
                        <slot name="brand"></slot>
                    </template>

                    <template #trigger>
                        <slot name="mobile-trigger-icon"></slot>
                    </template>

                    <template #menu-links="{ onNavLinkClick }">
                        <slot name="mobile-links" :onNavLinkClick></slot>
                    </template>
                </NavbarMobile>
            </template>

            <NavigationMenu
                v-else
                as="nav"
                class="max-w-none justify-normal"
                v-model="currentTrigger">
                <slot name="brand"></slot>

                <div class="flex-grow"></div>

                <NavigationMenuList class="flex gap-3 text-lg text-white lg:text-sm">
                    <slot name="desktop-links" :navigate :isNavigating></slot>
                    <slot name="search"></slot>
                </NavigationMenuList>
            </NavigationMenu>
        </div>
    </div>
</template>

<script setup lang="ts">
import { NavigationMenu, NavigationMenuList } from '@/components/ui/navigation-menu'
import { NavbarMobile } from './index'
import { ref, type HTMLAttributes } from 'vue'
import { swup, useSwup } from '@/lib/swup'
import { cn } from '@/lib/utils'
import { useNavContext } from '../composables'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'

interface Props {
    class?: HTMLAttributes['class']
    title?: string
    transparent?: boolean
}

const props = defineProps<Props>()

const breakpoints = useBreakpoints(breakpointsTailwind)
const xlAndGreater = breakpoints.greater('xl')

const { isVisiting: isNavigating } = useSwup()

const navigate = (e: Event) => {
    e.preventDefault()
    const target = e.target as HTMLAnchorElement

    const href = target.getAttribute('href')

    if (!href) return

    swup.navigate(href)
}

const { isSolidColorNav, isNavTransparent } = useNavContext(props.transparent)

const currentTrigger = ref('')
</script>

<style scoped></style>
