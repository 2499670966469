import { createApp, defineComponent, ref } from 'vue'
import { BlockModule } from '../common'
import './button.css'
import ButtonShareDialog from './components/ButtonShareDialog.vue'
import { useEventListener } from '@vueuse/core'

new BlockModule({
    selector: '.block-button',
    setupBlock(el, onTeardown) {
        const button = el.querySelector('.button-block__button')
        if (!button) return

        // We don't want the share dialog to take over the entire button as
        // it may cause the buttton to flicker and shift.
        // Hence, we only take over the element that is supposed to house the dialog.
        const shareDialogAppEl = el.querySelector('.share-dialog-app')
        if (!shareDialogAppEl) return

        const shareDialogVueApp = createApp(
            defineComponent({
                components: {
                    ButtonShareDialog,
                },
                setup() {
                    const showShareDialog = ref(false)

                    useEventListener(button, 'click', () => {
                        showShareDialog.value = true
                    })

                    return {
                        showShareDialog,
                    }
                },
            }),
        )

        shareDialogVueApp.mount(shareDialogAppEl)

        onTeardown(() => {
            shareDialogVueApp.unmount()
        })
    },
})
