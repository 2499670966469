import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import { countTo, doIfInViewOrDefer, trendColorClasses, trendIconClasses } from './common'
import { getMarketDataForeignExUsdMyrReferenceRate } from '@/api'
import { defineComponent } from 'vue'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'

new BlockModule({
    selector: '.block-market-data-foreign-ex-usd-myr-reference-rate',
    vueComponent: defineComponent({
        components: { ChartShareButtons },
        setup() {},
    }),
    async setupBlock(el, onTeardown, blockContext) {
        const chartData = await getMarketDataForeignExUsdMyrReferenceRate()

        const latestValue = chartData.data[chartData.data.length - 1]
        const beforeLatestValue = chartData.data[chartData.data.length - 2]
        const valueDelta = (latestValue.y || 0) - (beforeLatestValue.y || 0)

        const timestamp = chartData.lastUpdated
        const date = new Date(timestamp)
        const lastUpdatedValue = date
            .toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            })
            .replace(',', '')
        let trend: keyof typeof trendColorClasses & keyof typeof trendIconClasses = 'neutral'

        if (valueDelta > 0) {
            trend = 'positive'
        } else if (valueDelta < 0) {
            trend = 'negative'
        }

        const setupCounters = () => {
            const previousValue = el.querySelector('.previous-value')
            if (previousValue) {
                previousValue.classList.add(trendColorClasses[trend])
            }

            const previousValueIcon = el.querySelector('.previous-value-icon')
            if (previousValueIcon) {
                previousValueIcon.classList.add(trendIconClasses[trend])
            }

            const previousValueLabel = el.querySelector('.previous-value-label')
            if (previousValueLabel) {
                previousValueLabel.textContent = `(${latestValue.x})`
            }

            const currentValueEl = el.querySelector('.current-value-counter')
            if (currentValueEl) {
                countTo(latestValue.y || 0, (val) => {
                    currentValueEl.textContent = val.toString()
                })
            }

            const previousValueEl = el.querySelector('.previous-value-counter')
            if (previousValueEl) {
                countTo(Math.abs(valueDelta), (val) => {
                    previousValueEl.textContent = val.toString()
                })
            }

            const lastUpdatedEl = el.querySelector('.last-updated')
            if (lastUpdatedEl && timestamp) {
                lastUpdatedEl.textContent = `Last updated: ${lastUpdatedValue}`
            }
        }

        const setupChart = async () => {
            const chartEl = el.querySelector('.chart-container')
            if (!chartEl) return

            const Highcharts = await importHighcharts()

            const chart = Highcharts.chart({
                chart: {
                    type: 'line',
                    renderTo: chartEl as HTMLElement,
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories: chartData.data.map((x) => x.x.toString()),
                    lineColor: '#DBDBDB',
                },
                legend: {
                    align: 'right',
                    verticalAlign: 'top',
                },
                yAxis: {
                    color: '#DBDBDB',
                    title: {
                        text: '(%)',
                        align: 'high',
                        rotation: 0,
                        reserveSpace: false,
                        textAlign: 'left',
                        y: -20,
                    },
                },
                credits: { enabled: false },
                series: [
                    {
                        name: chartData.yLabel,
                        type: 'line',
                        color: '#010066',
                        data: chartData.data.map((x) => x.y),
                    },
                ],
                exporting: {
                    enabled: false,
                    filename: 'foreign-ex-usd-myr-reference-rate',
                },
                tooltip: {
                    valueDecimals: 4,
                },
            })

            const download = el.querySelector<HTMLButtonElement>('.download-btn')
            if (download) {
                download.addEventListener('click', () => {
                    chart.downloadCSV()
                })
            }

            onTeardown(() => {
                chart.destroy()
            })
        }

        doIfInViewOrDefer(() => {
            setupChart()
            setupCounters()
        }, blockContext)
    },
})
