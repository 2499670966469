import Swup from 'swup'
import SwupFragmentPlugin from '@swup/fragment-plugin'
import SwupHeadPlugin from '@swup/head-plugin'
import { ref } from 'vue'

const isVisiting = ref(false)

// TODO: set the below dynamically
export const swupFragmentPlugin = new SwupFragmentPlugin({
    rules: [
        {
            from: '/frequently-asked-questions',
            to: '/frequently-asked-questions/:topicPath',
            containers: ['#faq-topic-contents', '#faq-topic-links', '#banner-content'],
            scroll: true,
        },
        {
            from: '/frequently-asked-questions/:topicPath',
            to: '/frequently-asked-questions/:topicPath',
            containers: ['#faq-topic-contents', '#faq-topic-links', '#banner-content'],
            scroll: true,
        },
        {
            from: '/laws-regulations',
            to: '/laws-regulations/:topicPath',
            containers: ['#faq-topic-contents', '#faq-topic-links', '#banner-content'],
            scroll: true,
        },
        {
            from: '/laws-regulations/:topicPath',
            to: '/laws-regulations/:topicPath',
            containers: ['#faq-topic-contents', '#faq-topic-links', '#banner-content'],
            scroll: true,
        },
    ],
})

export const swup = new Swup({
    containers: ['#swup'],
    plugins: [
        swupFragmentPlugin,
        new SwupHeadPlugin({
            persistTags:
                '[data-swup-keep], [data-vite-dev-id], [href*="vue-devtools"], [rel="modulepreload"], [rel="stylesheet"]',
        }),
    ],
})

swup.hooks.on('visit:start', () => {
    isVisiting.value = true
})
swup.hooks.on('visit:end', () => {
    isVisiting.value = false
})

export const useSwup = () => {
    return {
        isVisiting,
    }
}
