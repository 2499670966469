import { defineComponent } from 'vue'
import { BlockModule } from '../common'
import '../button/button.css'
import GenericListing from '@/components/website/generic-listing/components/GenericListing.vue'

new BlockModule({
    selector: '.block-generic-listing',
    vueComponent: defineComponent({
        components: { 
            GenericListing,
        },
    }),
    setupBlock() {

    },
})
