import gsap from 'gsap'
import type { BlockContext } from '../common'

export const trendColorClasses = {
    positive: 'text-green-500',
    negative: 'text-rubyRed',
    neutral: 'text-lightBlue',
}

export const trendIconClasses = {
    positive: 'rotate-180',
    negative: 'rotate-0',
    neutral: '-rotate-90',
}

export const chartLineColors = [
    '#010066',
    '#91C8F3',
    '#686868',
    '#E57315',
    '#D150FF',
    '#15D9E5',
    '#212529',
]

export const countTo = (
    to: number,
    onUpdate: (value: number) => void,
    from = 0.0,
    durationInSeconds = 2,
) => {
    gsap.to(
        { value: from },
        {
            value: to,
            duration: durationInSeconds,
            ease: 'power1.out',
            onUpdate: function () {
                onUpdate(this.targets()[0].value.toFixed(1))
            },
        },
    )
}

export const doIfInViewOrDefer = (callback: () => void, context: BlockContext) => {
    if (context.isInView.value) {
        callback()
        return
    }

    context.onInView(callback)
}
