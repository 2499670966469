import { importGsap, importScrollTrigger } from '@/lib/gsap'
import { isMediaLoaded } from '@/lib/media'

const gsap = importGsap()
const ScrollTrigger = importScrollTrigger()

export class AnimateElement extends HTMLElement {
    constructor() {
        super()
    }

    connectedCallback() {
        const motionOK = !window.matchMedia('(prefers-reduced-motion: reduce)').matches
        if (!motionOK) return

        this.beforeInit()

        ScrollTrigger.create({
            trigger: this,
            onEnter: async () => {
                if (!this.immediate && this.media) {
                    await isMediaLoaded(this.media)
                }
                this.init()
            },
            once: true,
        })
    }

    get immediate() {
        return this.hasAttribute('data-immediate')
    }

    get media() {
        return Array.from(this.querySelectorAll('img, iframe, svg, g-map'))
    }

    get type() {
        return this.dataset.animate || 'fade-up'
    }

    get delay() {
        return parseInt(this.dataset.animateDelay || '0') / 1000
    }

    get paused() {
        return this.hasAttribute('paused')
    }

    async beforeInit() {
        if (this.paused) return
        if (!gsap) return

        switch (this.type) {
            case 'fade-in':
                gsap.set(this, {
                    autoAlpha: 0,
                })
                break

            case 'fade-up':
                gsap.set(this, {
                    y: 32,
                    autoAlpha: 0,
                })
                break

            case 'fade-up-large':
                gsap.set(this, {
                    yPercent: 90,
                    autoAlpha: 0,
                })
                break

            case 'zoom-out':
                gsap.set(this, { scale: 1.3 })
                break
        }
    }

    async init() {
        if (this.paused) return
        if (!gsap) return

        switch (this.type) {
            case 'fade-in':
                await gsap.to(this, {
                    autoAlpha: 1,
                    duration: 1.5,
                    delay: this.delay,
                    ease: 'expo.out',
                })
                break

            case 'fade-up':
                await gsap.to(this, {
                    y: 0,
                    autoAlpha: 1,
                    duration: 1.5,
                    delay: this.delay,
                    ease: 'expo.out',
                })
                break

            case 'fade-up-large':
                await gsap.to(this, {
                    yPercent: 0,
                    autoAlpha: 1,
                    delay: this.delay,
                    ease: 'expo.out',
                })
                break

            case 'zoom-out':
                await gsap.to(this, {
                    scale: 1,
                    duration: 1.3,
                    delay: this.delay,
                    ease: 'expo.out',
                })
                break
        }

        this.classList.add('animate')
    }

    async reset(duration: number) {
        if (!gsap) return

        switch (this.type) {
            case 'fade-in':
                await gsap.to(this, {
                    autoAlpha: 0,

                    duration: duration ? duration : 1.5,
                    delay: this.delay,
                    ease: duration ? 'none' : 'expo.out',
                })
                break

            case 'fade-up':
                await gsap.to(this, {
                    y: 32,
                    autoAlpha: 0,
                    duration: duration ? duration : 1.5,
                    delay: this.delay,
                    ease: duration ? 'none' : 'expo.out',
                })
                break

            case 'fade-up-large':
                await gsap.to(this, {
                    yPercent: 90,
                    autoAlpha: 0,
                    duration: duration ? duration : 1,
                    delay: this.delay,
                    ease: duration ? 'none' : 'expo.out',
                })
                break

            case 'zoom-out':
                await gsap.to(this, {
                    scale: 0,
                    duration: duration ? duration : 1.3,
                    delay: this.delay,
                    ease: duration ? 'none' : 'expo.out',
                })
                break
        }

        this.classList.remove('animate')
    }

    refresh() {
        this.removeAttribute('paused')
        this.beforeInit()
        this.init()
    }
}
