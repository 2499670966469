import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type {
    MarketDataForeignExDailyFxExchange,
    MarketDataForeignExInterbankSwap,
    MarketDataForeignExUsdMyrInterbankIntradayRate,
    MarketDataForeignExUsdMyrReferenceRate,
} from '@/types'
import axios from 'axios'

export const getMarketDataForeignExDailyFxExchange = async () => {
    const request = axios.get<APIResponse<MarketDataForeignExDailyFxExchange>>(
        `${ENDPOINTS.MARKET_DATA_V1}/foreign-exchange/daily-fx-exchange`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataForeignExInterbankSwap = async () => {
    const request = axios.get<APIResponse<MarketDataForeignExInterbankSwap[]>>(
        `${ENDPOINTS.MARKET_DATA_V1}/foreign-exchange/interbank-swap`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataForeignExUsdMyrInterbankIntradayRate = async () => {
    const request = axios.get<APIResponse<MarketDataForeignExUsdMyrInterbankIntradayRate[]>>(
        `${ENDPOINTS.MARKET_DATA_V1}/foreign-exchange/usd-myr-interbank-intraday-rate`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataForeignExUsdMyrReferenceRate = async () => {
    const request = axios.get<APIResponse<MarketDataForeignExUsdMyrReferenceRate>>(
        `${ENDPOINTS.MARKET_DATA_V1}/foreign-exchange/usd-myr-reference-rate`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
