import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type {
    MarketDataMonetaryOpr,
    MarketDataMonetaryIMyorRate,
    MarketDataMonetaryIMyorVolume,
    MarketDataMonetaryInterestRate,
    MarketDataMonetaryInterestVolume,
    MarketDataMonetaryIslamicInterbankRate,
} from '@/types'
import axios from 'axios'

export const getMarketDataMonetaryOpr = async () => {
    const request = axios.get<APIResponse<MarketDataMonetaryOpr>>(
        `${ENDPOINTS.MARKET_DATA_V1}/monetary/opr`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataMonetaryIMyorRate = async () => {
    const request = axios.get<APIResponse<MarketDataMonetaryIMyorRate>>(
        `${ENDPOINTS.MARKET_DATA_V1}/moneymarket/i-myor-rate`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataMonetaryIMyorVolume = async () => {
    const request = axios.get<APIResponse<MarketDataMonetaryIMyorVolume>>(
        `${ENDPOINTS.MARKET_DATA_V1}/moneymarket/i-myor-volume`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataMonetaryInterestRate = async () => {
    const request = axios.get<APIResponse<MarketDataMonetaryInterestRate>>(
        `${ENDPOINTS.MARKET_DATA_V1}/moneymarket/interest_rate`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataMonetaryInterestVolume = async () => {
    const request = axios.get<APIResponse<MarketDataMonetaryInterestVolume>>(
        `${ENDPOINTS.MARKET_DATA_V1}/moneymarket/interest_volume`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataMonetaryIslamicInterbankRate = async () => {
    const request = axios.get<APIResponse<MarketDataMonetaryIslamicInterbankRate>>(
        `${ENDPOINTS.MARKET_DATA_V1}/moneymarket/islamic_interbank_rate`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
