<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.2101 8.45031C14.0397 8.45031 13.8762 8.51804 13.7556 8.63859C13.6351 8.75915 13.5673 8.92265 13.5673 9.09314V11.6808C13.5668 12.1806 13.368 12.6597 13.0146 13.0131C12.6612 13.3665 12.182 13.5653 11.6822 13.5659H4.31868C3.8189 13.5653 3.33975 13.3665 2.98635 13.0131C2.63295 12.6597 2.43417 12.1806 2.4336 11.6808V4.31722C2.43417 3.81744 2.63295 3.33829 2.98635 2.98489C3.33975 2.63149 3.8189 2.4327 4.31868 2.43213H6.90632C7.07681 2.43213 7.24031 2.36441 7.36087 2.24385C7.48142 2.1233 7.54915 1.9598 7.54915 1.78931C7.54915 1.61882 7.48142 1.45532 7.36087 1.33476C7.24031 1.21421 7.07681 1.14648 6.90632 1.14648H4.31868C3.47806 1.14748 2.67214 1.48185 2.07773 2.07627C1.48332 2.67068 1.14894 3.47659 1.14795 4.31722V11.6808C1.14894 12.5214 1.48332 13.3273 2.07773 13.9217C2.67214 14.5161 3.47806 14.8505 4.31868 14.8515H11.6822C12.5229 14.8505 13.3288 14.5161 13.9232 13.9217C14.5176 13.3273 14.852 12.5214 14.853 11.6808V9.09314C14.853 8.92265 14.7852 8.75915 14.6647 8.63859C14.5441 8.51804 14.3806 8.45031 14.2101 8.45031Z"
            fill="currentColor" />
        <path
            d="M14.1964 1.14258H10.2002C10.0318 1.14255 9.87022 1.20854 9.75002 1.32638C9.62982 1.44423 9.56065 1.60452 9.55735 1.77281C9.55065 2.1336 9.85412 2.42823 10.2146 2.42823H12.6609L7.54129 7.5494C7.42075 7.66995 7.35303 7.83345 7.35303 8.00393C7.35303 8.17441 7.42075 8.33791 7.54129 8.45846C7.66184 8.579 7.82534 8.64672 7.99582 8.64672C8.1663 8.64672 8.3298 8.579 8.45035 8.45846L13.5715 3.3389V5.79395C13.5715 5.96444 13.6392 6.12794 13.7598 6.2485C13.8804 6.36905 14.0439 6.43678 14.2143 6.43678C14.3848 6.43678 14.5483 6.36905 14.6689 6.2485C14.7894 6.12794 14.8572 5.96444 14.8572 5.79395V1.80308C14.8572 1.71632 14.8401 1.63041 14.8069 1.55026C14.7737 1.4701 14.725 1.39728 14.6636 1.33594C14.6023 1.2746 14.5294 1.22596 14.4492 1.19278C14.3691 1.1596 14.2832 1.14254 14.1964 1.14258Z"
            fill="currentColor" />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
