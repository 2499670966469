<script setup lang="ts" generic="Item">
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import type { CommonProps } from './types'
import { computed } from 'vue'
import kebabCase from 'lodash/kebabCase'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { XIcon } from 'lucide-vue-next'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

interface Props {
    errorMessage?: string
    items?: Item[]
    itemText: keyof Item & string
    itemValue: keyof Item & string
    clearable?: boolean
    triggerClass?: string
}
const props = withDefaults(defineProps<CommonProps & Props>(), {
    items: () => [],
})

const idAttr = computed(() => {
    return props.id ? props.id : 'txt-' + kebabCase(props.name)
})

const getItemText = (item?: unknown) => {
    if (!item) return ''
    if (typeof item === 'string') return item
    if (!props.itemText) return ''
    return item[props.itemText as keyof typeof item]
}

const getItemValue = (item?: unknown) => {
    if (!item) return ''
    if (typeof item === 'string') return item
    return item[props.itemValue as keyof typeof item]
}

const modelValue = defineModel<string>()
</script>

<template>
    <div :class="cn('flex w-full flex-col', $props.class, errorMessage && 'is-invalid')">
        <Label v-if="label" :class="cn('mb-2', errorMessage && 'text-destructive')" :for="idAttr">
            {{ label }}
        </Label>
        <Select v-model="modelValue">
            <div class="relative">
                <SelectTrigger
                    :class="triggerClass"
                    chevron-class="text-secondary dark:text-foreground"
                    :disabled>
                    <SelectValue :placeholder="placeholder" />
                </SelectTrigger>
                <TooltipProvider v-if="clearable && modelValue">
                    <Tooltip>
                        <TooltipTrigger as-child :disabled>
                            <Button
                                @click="modelValue = ''"
                                variant="ghost"
                                class="absolute end-10 top-1/2 h-3 w-3 -translate-y-1/2 p-0"
                                type="button">
                                <XIcon></XIcon>
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            <span class="text-xs">Clear</span>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div>

            <p v-if="errorMessage" class="text-sm text-destructive">{{ errorMessage }}</p>

            <SelectContent :selectContentAlign>
                <SelectGroup>
                    <SelectItem
                        v-for="(item, index) in items"
                        :key="index"
                        :value="getItemValue(item)">
                        {{ getItemText(item) }}
                    </SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    </div>
</template>
