<template>
    <Dialog v-model:open="modelValue">
        <DialogContent
            class="border-0 bg-transparent shadow-none sm:max-w-[425px]"
            hide-close-button>
            <DialogClose
                class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-gray-100 data-[state=open]:text-gray-500 dark:ring-offset-gray-950 dark:focus:ring-gray-300 dark:data-[state=open]:bg-gray-800 dark:data-[state=open]:text-gray-400">
                <XIcon class="h-4 w-4 text-background" />
                <span class="sr-only">Close</span>
            </DialogClose>

            <DialogHeader>
                <DialogTitle class="text-center text-background">Share Link</DialogTitle>
                <DialogDescription class="text-center text-background">
                    You may share this page using the following ways
                </DialogDescription>
            </DialogHeader>

            <ShareIcons class="justify-center"></ShareIcons>
        </DialogContent>
    </Dialog>
</template>

<script setup lang="ts">
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    Dialog,
    DialogTitle,
    DialogClose,
} from '@/components/ui/dialog'
import ShareIcons from '@/components/website/share-socials/share-icons.vue'
import { XIcon } from 'lucide-vue-next'

const modelValue = defineModel<boolean>()
</script>

<style scoped></style>
