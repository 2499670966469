import { BlockModule } from './common'

new BlockModule({
    selector: '.block-icon-text-list',
    // inViewDebugMode: true,
    async setupBlock(el, onTeardown, context) {

        const [ { gsap } ] = await Promise.all([import('gsap')])

        const slides = el.querySelectorAll('.icon-text-card')

        console.log(slides)

        const timeline = gsap.timeline({
            paused: true,
        })

        timeline.from(slides, {
            x: 100,
            stagger: 0.1,
            opacity: 0,
            duration: 0.5,
            ease: 'power2.out',
        })

        context.onInView(() => {
            timeline.play()
        })

        onTeardown(() => {
            timeline.revert()
        })
    },
})
