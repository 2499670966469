<template>
    <div>
        <FiltersTemplate>
            <div class="container flex flex-col-reverse items-start justify-between gap-3 lg:mt-0 lg:mb-16 lg:flex-row lg:items-center">
                <div class="relative py-6 hidden lg:flex h-full w-full items-center gap-2 lg:w-9/12 lg:10/12 lg:gap-4">
                    <IconChevronRight class="btn-prev size-7 flex-shrink-0 rotate-180 text-rubyRed">
                    </IconChevronRight>

                    <SwiperVue
                        class="relative flex-1"
                        :modules="[Navigation]"
                        effect="slide"
                        space-between="8"
                        slides-per-view="auto"
                        grab-cursor
                        :navigation="{
                            prevEl: '.btn-prev',
                            nextEl: '.btn-next',
                        }"
                        :breakpoints="{
                            992: {
                                spaceBetween: 16,
                            },
                        }"
                        @swiper="setSwiperInstance"
                        @slide-change-transition-start="updateSwiper"
                        @slide-change-transition-end="updateSwiper">
                        <SwiperSlide
                            v-for="(item, index) in listingCategoriesWithAll"
                            :key="item.Id"
                            :class="{
                                'max-w-fit select-none text-nowrap rounded-full border border-primary px-4 py-2 font-bold leading-none text-primary body-2 lg:px-8 lg:py-4 lg:heading-6': true,
                                'bg-primary text-white': filters.tagIds.includes(item.Id),
                            }"
                            @click="onCategoryFilterChange(item.Id)">
                            {{ item.Name }}
                        </SwiperSlide>
                    </SwiperVue>

                    <IconChevronRight class="btn-next size-7 flex-shrink-0 text-rubyRed">
                    </IconChevronRight>
                </div>

                <div class="flex flex-wrap lg:hidden relative py-4 h-full w-full items-center gap-2">
                    <button
                        v-for="(item, index) in listingCategoriesWithAll"
                        :key="item.Id"
                        @click="onCategoryFilterChange(item.Id)"
                        :class="{
                        'flex-grow select-none rounded-full border border-primary px-4 py-2 font-bold text-primary body-2 lg:px-8 lg:py-4 lg:heading-6': true,
                        'bg-primary text-white': filters.tagIds.includes(item.Id),
                        }"
                    >
                        {{ item.Name }}
                    </button>
                </div>

                <div class="w-full lg:w-2/12 border-y border-lightGrey lg:border-y-0 py-2 lg:py-0">
                    <div class="flex items-center justify-start lg:justify-end gap-2">
                        <span class="text-nowrap text-midGrey body-2">Sort By</span>
                        <Select v-model="filters.sorting">
                            <SelectSorting class="border-none p-0 font-bold text-primary body-2 max-w-fit">
                                <SelectValue></SelectValue>
                            </SelectSorting>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value="0">Title A - Z</SelectItem>
                                    <SelectItem value="1">Title Z - A</SelectItem>
                                    <SelectItem value="2">Latest</SelectItem>
                                    <SelectItem value="3">Oldest</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            </div>
        </FiltersTemplate>

        <Drawer v-if="!mdAndGreater">
            <DrawerTrigger class="w-full border-b-2 border-yellow py-5">
                <span class="container flex items-center justify-between">
                    <span class="font-bold text-primary">Filter By ({{ filterCount }})</span>
                    <IconFilter class="size-8"></IconFilter>
                </span>
            </DrawerTrigger>
            <DrawerContent>
                <div class="flex flex-col">
                    <div class="p-4">
                        <DrawerTitle>Filters</DrawerTitle>
                        <VisuallyHidden>
                            <DrawerDescription>Filter the announcements</DrawerDescription>
                        </VisuallyHidden>
                    </div>

                    <UseFiltersTemplate></UseFiltersTemplate>
                </div>

                <DrawerFooter>
                    <DrawerClose as-child>
                        <BnmButton class="w-full" label="Apply"></BnmButton>
                    </DrawerClose>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>

        <div v-else>
            <UseFiltersTemplate></UseFiltersTemplate>
        </div>

        <div v-if="isBusy" class="container pb-20 pt-10 text-center">
            <h4 class="text-primary heading-4">Loading list...</h4>
        </div>

        <div
            v-else-if="!isBusy && (!genericListingAsync || genericListingAsync.items.length === 0)"
            class="container pb-20 pt-10 text-center">
            <h4 class="text-primary heading-4">No items found matching your filters</h4>
        </div>

        <div
            v-else-if="!isBusy && genericListingAsync && genericListingAsync.items.length > 0"
            class="container pt-10 lg:pt-0 mb-20 grid grid-cols-1 gap-x-5 gap-y-10 lg:grid-cols-2 lg:grid-cols-3 lg:gap-y-20">
            <div
                v-for="item in genericListingAsync.items"
                :key="item.id"
                class="relative flex h-full flex-col justify-between cursor-pointer"
                @click="item.itemContentType === 'Video Upload' || item.itemContentType === 'Youtube Embed' ? activePlayerId = item.key : null">
                <div class="relative mb-8">
                    <div
                        v-if="
                            item.itemContentType === 'Details Page' ||
                                item.itemContentType === 'File Upload'
                        ">
                        <LazyImage 
                            :src="item.thumbnailImage" 
                            :alt="item.title" 
                            class="pointer-events-none aspect-[420/280] h-full w-full select-none rounded-3xl object-cover"
                        ></LazyImage>
                    </div>
                    <div v-else>
                        <LazyImage
                            :src="item.thumbnailImage"
                            :alt="item.title"
                            class="aspect-[420/280] rounded-3xl object-cover"
                        ></LazyImage>
                        <div
                            class="black-overlay absolute inset-0 flex aspect-[420/280] items-center justify-center rounded-3xl bg-black opacity-60"
                            >
                            <button
                                class="z-2 m-auto flex h-[6rem] w-[6rem] items-center justify-center p-2"
                                type="button">
                                <PlayButton class="absolute"></PlayButton>
                            </button>
                        </div>
                    </div>
                </div>

                <a
                    v-if="item.itemContentType === 'Details Page'"
                    :href="item.ctaLink"
                    :target="item.ctaLinkTarget"
                    class="flex h-full flex-col no-underline after:absolute after:inset-0">
                    <div class="flex h-full flex-col">
                        <p
                            class="mb-2 max-w-fit rounded-sm bg-paleBlue p-[0.375rem] font-bold text-primary tiny">
                            {{ item.tag.tagName }}
                        </p>

                        <h5 class="mb-1 text-primary heading-5">{{ item.title }}</h5>

                        <DateLabel
                            :iso-date="item.publishedDate"
                            label-display-mode="short"
                            class="mb-4 text-midGrey body-2 before:content-none"></DateLabel>

                        <IconChevronRight
                            class="mt-auto size-6 flex-shrink-0 self-end text-rubyRed lg:size-8 lg:self-start">
                        </IconChevronRight>
                    </div>
                </a>
                <a
                    v-else-if="item.itemContentType === 'File Upload' && item.fileUpload"
                    :href="item.fileUpload"
                    download
                    class="flex h-full flex-col no-underline after:absolute after:inset-0">
                    <div class="flex h-full flex-col">
                        <p
                            class="mb-2 max-w-fit rounded-sm bg-paleBlue p-[0.375rem] font-bold text-primary tiny">
                            {{ item.tag.tagName }}
                        </p>

                        <h5 class="mb-1 text-primary heading-5">{{ item.title }}</h5>

                        <DateLabel
                            :iso-date="item.publishedDate"
                            label-display-mode="short"
                            class="mb-4 text-midGrey body-2 before:content-none"></DateLabel>

                        <IconChevronRight
                            class="mt-auto size-6 flex-shrink-0 self-end text-rubyRed lg:size-8 lg:self-start">
                        </IconChevronRight>
                    </div>
                </a>
                <div v-else class="flex h-full flex-col">
                    <p
                        class="mb-2 max-w-fit rounded-sm bg-paleBlue p-[0.375rem] font-bold text-primary tiny">
                        {{ item.tag.tagName }}
                    </p>

                    <h5 class="mb-1 text-primary heading-5">{{ item.title }}</h5>

                    <DateLabel
                        :iso-date="item.publishedDate"
                        label-display-mode="short"
                        class="mb-4 text-midGrey body-2 before:content-none"></DateLabel>

                    <IconChevronRight
                        class="mt-auto size-6 flex-shrink-0 self-end text-rubyRed lg:size-8 lg:self-start">
                    </IconChevronRight>
                </div>

                <Dialog
                    :open="activePlayerId === item.key"
                    @update:open="onPlayerDialogModelValueUpdate">
                    <DialogContent
                        class="max-w-6xl rounded-none border-0 bg-transparent p-0"
                        hide-close-button>
                        <template #default>
                            <DialogTitle class="sr-only">Video Player</DialogTitle>
                            <DialogDescription class="sr-only"
                            >This video player is playing {{ item.name }}
                            </DialogDescription>

                            <div
                                v-if="
                                    item.itemContentType === 'Youtube Embed' && item.youtubeVideo
                                ">
                                <div
                                    v-html="item.youtubeVideo.html"
                                    class="aspect-h-9 aspect-w-16 relative w-full"></div>
                            </div>

                            <div
                                v-else-if="
                                    item.itemContentType === 'Video Upload' && item.videoUpload
                                ">
                                <video id="modal-video" controls autoplay class="w-full rounded-lg">
                                    <source :src="item.videoUpload" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </template>
                    </DialogContent>
                </Dialog>
            </div>
        </div>

        <Pagination
            v-if="!isBusy && genericListingAsync"
            v-model="pageNumber"
            :page-size="genericListingAsync.pageSize"
            :total-pages="genericListingAsync.totalPages"
            @page-change="onPageChange"
            class="mt-10 lg:mt-20">
        </Pagination>
    </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, toRef, watch } from 'vue'
import { Select, SelectContent, SelectGroup, SelectItem, SelectValue } from '@/components/ui/select'
import SelectSorting from './SelectSorting.vue'
import DateLabel from '@/components/website/dates/DateLabelWithTooltip.vue'
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerTitle, DrawerTrigger } from '@/components/ui/drawer'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@/components/ui/dialog'
import { Swiper as SwiperVue, SwiperSlide } from 'swiper/vue'
import { Swiper as SwiperClass } from 'swiper'
import { Navigation } from 'swiper/modules'
import { createGenericListingComputedAsync } from '../datasource'
import { Pagination } from '@/components/ui/custom-pagination'
import { IconChevronRight, IconFilter, PlayButton } from '../../icons'
import type { ListingCategoryJson } from '../types'
import { breakpointsTailwind, createReusableTemplate, useBreakpoints } from '@vueuse/core'
import { BnmButton } from '../../button'
import { VisuallyHidden } from 'radix-vue'

import 'swiper/css'
import 'swiper/scss/navigation'
import { LazyImage } from '../../lazy-image'

interface Props {
    categories: string
    listingId: string
}

const props = defineProps<Props>()

const [FiltersTemplate, UseFiltersTemplate] = createReusableTemplate()

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndGreater = breakpoints.greater('md')

const filters = reactive({
    tagIds: [''] as string[],
    sorting: '0',
    pageNumber: 1,
    pageSize: 9,
})

const activePlayerId = ref<string>('')
const swiperInstance = ref<SwiperClass>()

const onPlayerDialogModelValueUpdate = (isOpen: boolean) => {
    if (!isOpen) {
        activePlayerId.value = ''
    }
}

const isBusy = ref(false)
const pageNumber = ref(1)
const selectedValues = ref<string[]>([''])

const genericListingAsync = createGenericListingComputedAsync(
    props.listingId,
    toRef(filters, 'tagIds'),
    toRef(filters, 'sorting'),
    pageNumber,
    toRef(filters, 'pageSize'),
    { evaluating: isBusy },
)

const parsedCategories = computed((): Array<ListingCategoryJson> => {
    try {
        return JSON.parse(props.categories)
    } catch (e) {
        console.error('Error parsing categories JSON:', e)
        return []
    }
})

const listingCategoriesWithAll = computed(() => {
    if (!parsedCategories.value.length) return []

    return [
        {
            Id: '',
            Name: 'All',
        },
        ...parsedCategories.value,
    ]
})

const setSwiperInstance = (swiper: SwiperClass) => {
    swiperInstance.value = swiper
}

const updateSwiper = () => {
    swiperInstance.value?.update()
}

const onCategoryFilterChange = (tagId: string) => {
    if (tagId === '') {
        selectedValues.value = ['']
    } else {
        if (selectedValues.value.includes('')) {
            selectedValues.value = selectedValues.value.filter((value) => value !== '')
        }

        if (selectedValues.value.includes(tagId)) {
            selectedValues.value = selectedValues.value.filter((value) => value !== tagId)
        } else {
            selectedValues.value.push(tagId)
        }

        if (selectedValues.value.length === 0) {
            selectedValues.value.push('')
        }
    }
    filters.tagIds = selectedValues.value.slice()
}

const filterCount = computed(() => {
    let count = 0

    count += filters.tagIds.length

    return count
})

const onPageChange = (pageNumber: number) => {
    filters.pageNumber = pageNumber
    scrollToTop()
}

const scrollToTop = () => {
    const offset = 300

    window.scrollTo({
        top: offset,
        behavior: 'smooth',
    })
}

watch(
    () => [...filters.tagIds, ...filters.sorting].join('|'),
    () => {
        filters.pageNumber = 1
    },
)
</script>

<style scoped>
.swiper-button-disabled {
    opacity: 10%;
}
</style>
