import { defineComponent, ref } from 'vue'
import { BlockModule } from '../common'
import { ShareIcons } from '@/components/website/share-socials'

new BlockModule({
    selector: '.block-social-sharing',
    vueComponent: defineComponent({
        components: {
            ShareIcons,
        },
        setup() {},
    }),
})
