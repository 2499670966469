<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_24371_267370)">
            <circle cx="16" cy="16" r="15.5" stroke="#010066" />
            <path
                d="M16.5029 9.57535C16.5029 9.31025 16.288 9.09535 16.0229 9.09535C15.7578 9.09535 15.5429 9.31025 15.5429 9.57535L15.5429 18.2833L13.2 15.9405C13.0126 15.753 12.7087 15.753 12.5212 15.9405C12.3337 16.1279 12.3337 16.4319 12.5212 16.6193L15.6772 19.7753C15.7698 19.8679 15.8908 19.9148 16.0121 19.9159C16.0157 19.916 16.0193 19.916 16.0229 19.916C16.0299 19.916 16.037 19.9159 16.044 19.9156C16.1728 19.9205 16.3033 19.8737 16.4017 19.7753L19.5577 16.6193C19.7452 16.4319 19.7452 16.1279 19.5577 15.9405C19.3703 15.753 19.0664 15.753 18.8789 15.9405L16.5029 18.3166L16.5029 9.57535Z"
                fill="#010066" />
            <path
                d="M10.2189 17.146C10.2189 16.8809 10.004 16.666 9.73892 16.666C9.47382 16.666 9.25891 16.8809 9.25891 17.146V20.8236C9.25891 21.9723 10.1902 22.9036 11.3389 22.9036H20.661C21.8098 22.9036 22.741 21.9723 22.741 20.8236V17.146C22.741 16.8809 22.5261 16.666 22.261 16.666C21.9959 16.666 21.781 16.8809 21.781 17.146V20.8236C21.781 21.4421 21.2796 21.9436 20.661 21.9436H11.3389C10.7204 21.9436 10.2189 21.4421 10.2189 20.8236V17.146Z"
                fill="#010066" />
        </g>
        <defs>
            <clipPath id="clip0_24371_267370">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>
