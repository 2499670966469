import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type { MarketDataPopulationAgeGender, MarketDataMedianAgePopulation } from '@/types'
import axios, { type GenericAbortSignal } from 'axios'

export const getMarketDataPopulationAgeGender = async (
    year: string,
    signal?: GenericAbortSignal,
) => {
    const request = axios.get<APIResponse<MarketDataPopulationAgeGender>>(
        `${ENDPOINTS.MARKET_DATA_V1}/demograhic/age_gender_population?year=${year}`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataMedianAgePopulation = async ( signal?: GenericAbortSignal ) => {
    const request = axios.get<APIResponse<MarketDataMedianAgePopulation>>(
        `${ENDPOINTS.MARKET_DATA_V1}/demograhic/median_age_population`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
