<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_24371_267371)">
            <circle cx="16" cy="16" r="15.5" stroke="#010066" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 9.83398C19.1716 9.83398 18.5 10.5056 18.5 11.334C18.5 12.1624 19.1716 12.834 20 12.834C20.8284 12.834 21.5 12.1624 21.5 11.334C21.5 10.5056 20.8284 9.83398 20 9.83398ZM17.5 11.334C17.5 9.95327 18.6193 8.83398 20 8.83398C21.3807 8.83398 22.5 9.95327 22.5 11.334C22.5 12.7147 21.3807 13.834 20 13.834C18.6193 13.834 17.5 12.7147 17.5 11.334Z"
                fill="#010066" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 14.5C11.1716 14.5 10.5 15.1716 10.5 16C10.5 16.8284 11.1716 17.5 12 17.5C12.8284 17.5 13.5 16.8284 13.5 16C13.5 15.1716 12.8284 14.5 12 14.5ZM9.5 16C9.5 14.6193 10.6193 13.5 12 13.5C13.3807 13.5 14.5 14.6193 14.5 16C14.5 17.3807 13.3807 18.5 12 18.5C10.6193 18.5 9.5 17.3807 9.5 16Z"
                fill="#010066" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 19.166C19.1716 19.166 18.5 19.8376 18.5 20.666C18.5 21.4944 19.1716 22.166 20 22.166C20.8284 22.166 21.5 21.4944 21.5 20.666C21.5 19.8376 20.8284 19.166 20 19.166ZM17.5 20.666C17.5 19.2853 18.6193 18.166 20 18.166C21.3807 18.166 22.5 19.2853 22.5 20.666C22.5 22.0467 21.3807 23.166 20 23.166C18.6193 23.166 17.5 22.0467 17.5 20.666Z"
                fill="#010066" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.2947 16.7542C13.4337 16.5156 13.7398 16.4349 13.9784 16.5739L18.5317 19.2273C18.7703 19.3663 18.851 19.6724 18.712 19.911C18.573 20.1496 18.2669 20.2303 18.0283 20.0913L13.4749 17.4379C13.2363 17.2989 13.1556 16.9928 13.2947 16.7542Z"
                fill="#010066" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.7052 12.0879C18.8444 12.3264 18.7639 12.6326 18.5254 12.7718L13.9787 15.4251C13.7402 15.5643 13.434 15.4838 13.2948 15.2453C13.1556 15.0068 13.2362 14.7006 13.4747 14.5614L18.0213 11.9081C18.2598 11.7689 18.566 11.8494 18.7052 12.0879Z"
                fill="#010066" />
        </g>
        <defs>
            <clipPath id="clip0_24371_267371">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>
