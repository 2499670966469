import { ref } from 'vue'
import { getErrorMessage as getErrorMessageUtil } from '@/lib/api'
import axios from 'axios'
import { toast } from 'vue-sonner'

export const isActionInProgress = ref(false)

export async function useErrorHandling<T>(
    promise: Promise<T> | ((...args: any[]) => Promise<T>),
    getErrorMessage = getErrorMessageUtil,
) {
    try {
        isActionInProgress.value = true
        if (typeof promise === 'function') return await promise()
        return await promise
    } catch (error: unknown) {
        const errorMessage = getErrorMessage(error)
        const suppressToast = axios.isAxiosError(error) && error.config?.suppressToast
        if (!suppressToast) {
            toast.error(errorMessage.title || 'Network request failed', {
                description: errorMessage.description,
            })
        }
        throw error
    } finally {
        isActionInProgress.value = false
    }
}
