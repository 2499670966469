<template>
    <div :class="cn('flex w-full flex-col', $props.class, errorMessage && 'is-invalid')">
        <Label v-if="label" :class="cn('mb-2', errorMessage && 'text-destructive')" :for="idAttr">
            {{ label }}
        </Label>

        <Popover v-model:open="isOpen">
            <PopoverTrigger as-child :class="cn('')" :disabled>
                <Button
                    role="combobox"
                    :class="
                        cn(
                            'group relative h-14 w-full justify-between rounded-full border border-input bg-background px-6 text-inherit hover:bg-background',
                            buttonClass,
                        )
                    "
                    :aria-expanded="isOpen">
                    <div
                        class="flex flex-grow flex-nowrap items-center gap-1 truncate text-ellipsis text-start">
                        <template v-if="multiple && selectedItems?.map(getItemText)?.length">
                            <span
                                class="line-clamp w-full truncate text-ellipsis font-bold text-primary"
                                style="--line-clamp-lines: 1">
                                {{ selectedItems?.map(getItemText).join(', ') }}
                            </span>
                            <span class="font-bold text-primary">
                                ({{ selectedItems?.map(getItemText).length }})
                            </span>
                        </template>

                        <p v-else-if="hasModelValue" class="font-bold text-primary">
                            {{ getItemText(selectedItem) }}
                        </p>

                        <p v-if="!hasModelValue" class="font-normal text-muted-foreground">
                            {{ placeholder }}
                        </p>

                        <!-- Let's bring this back only when they ask for it -->
                        <!-- <TooltipProvider v-if="clearable && hasModelValue">
                            <Tooltip>
                                <TooltipTrigger as-child>
                                    <Button
                                        @click="reset"
                                        variant="ghost"
                                        class="absolute end-1 z-[2] size-3 h-4 w-4 rounded-full border border-midGrey p-0"
                                        type="button">
                                        <XIcon class="h-3 w-3 text-midGrey" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <span class="text-xs">Clear</span>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider> -->
                    </div>
                    <ChevronDown
                        class="size-4 shrink-0 text-rubyRed transition group-data-[state=open]:rotate-180 dark:text-foreground" />
                </Button>
            </PopoverTrigger>

            <p v-if="errorMessage" class="text-sm text-destructive">{{ errorMessage }}</p>

            <PopoverContent
                :class="cn('w-[--radix-popover-trigger-width] p-0', popoverContentClass)">
                <Command :name :multiple v-model:search-term="searchTerm">
                    <CommandInput
                        placeholder="Search"
                        class="border-transparent focus:border-transparent focus:ring-0" />
                    <CommandEmpty>{{ emptyResultsText }}</CommandEmpty>
                    <CommandList>
                        <CommandGroup class="max-h-64 overflow-auto">
                            <CommandItem
                                v-for="(item, index) in filteredItems"
                                :key="index"
                                :value="item as any"
                                @select="handleOptionSelected(item)"
                                :disabled="disabled">
                                <Check
                                    class="mr-2 h-4 w-4"
                                    :class="{
                                        'opacity-100': isSelected(getItemValue(item)),
                                        'opacity-0': !isSelected(getItemValue(item)),
                                    }" />
                                {{ getItemText(item) }}
                            </CommandItem>
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>
</template>

<script lang="ts" setup generic="Item">
import { computed, ref } from 'vue'
import { Check, ChevronDown } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@/components/ui/command'
import { XIcon } from 'lucide-vue-next'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import Label from '@/components/ui/label/Label.vue'
import type { CommonProps } from './types'
import kebabCase from 'lodash/kebabCase'

interface Props {
    items: Item[]
    itemText: keyof Item & string
    itemValue: keyof Item & string
    modelModifiers?: { lazy: boolean }
    emptyResultsText?: string
    popoverContentClass?: string
    buttonClass?: string
}

const props = withDefaults(defineProps<CommonProps & Props>(), {
    items: () => [],
    emptyResultsText: 'No results found',
    clearable: true,
})

const modelValue = defineModel<Array<string> | string>()
const hasModelValue = computed(() => {
    if (!modelValue.value) return false
    return props.multiple ? modelValue.value?.length > 0 : !!modelValue.value
})

const idAttr = computed(() => {
    return props.id ? props.id : 'txt-' + kebabCase(props.name)
})

const isOpen = ref(false)
const searchTerm = ref('')

const selectedItems = computed(() => {
    if (!modelValue.value) return []

    return props.multiple
        ? (modelValue.value as Array<string>)?.map((x) =>
              props.items.find((item) => item[props.itemValue] === x),
          ) ?? []
        : undefined
})

const selectedItem = computed(() => {
    return !props.multiple
        ? props.items.find((item) => item[props.itemValue] === modelValue.value)
        : undefined
})

const filteredItems = computed(() => {
    if (searchTerm.value === '') return props.items

    const result = props.items.filter((item) => {
        const searchText = searchTerm.value?.toLowerCase()
        const itemText = (item[props.itemText] as string)?.toLowerCase()

        return itemText.includes(searchText)
    })

    return result
})

const getItemText = (item?: unknown) => {
    if (!item) return ''
    if (typeof item === 'string') return item
    if (!props.itemText) return ''
    return item[props.itemText as keyof typeof item]
}

const getItemValue = (item?: unknown) => {
    if (!item) return ''
    if (typeof item === 'string') return item
    return item[props.itemValue as keyof typeof item]
}

const isSelected = (value: string): boolean => {
    if (props.multiple) {
        return modelValue.value?.includes(value) ?? false
    }

    return modelValue.value === value
}

const handleUnselected = (selectedValue: string): void => {
    if (props.multiple) {
        modelValue.value =
            (modelValue.value as Array<string>)?.filter((x) => x !== selectedValue) ?? []
    } else {
        modelValue.value = selectedValue
    }
}

const handleOptionSelected = (item: Item): void => {
    const itemValue = getItemValue(item)

    if (props.multiple) {
        if (isSelected(itemValue)) {
            modelValue.value =
                (modelValue.value as Array<string>)?.filter((x) => x !== itemValue) ?? []
        } else {
            modelValue.value = modelValue.value ? [...modelValue.value, itemValue] : [itemValue]
        }

        isOpen.value = true
    } else {
        modelValue.value = isSelected(itemValue) ? '' : itemValue
    }
}

const reset = () => {
    modelValue.value = props.multiple ? [] : ''
}
</script>
