let highcharts: typeof import('highcharts') | null = null

// Import this expensive library here
export const importHighcharts = async () => {
    if (highcharts) return highcharts

    const [
        Highcharts,
        { default: HighchartsBoost },
        { default: HighchartsExporting },
        { default: HighchartsExportData },
    ] = await Promise.all([
        await import('highcharts'),
        await import('highcharts/modules/boost'),
        await import('highcharts/modules/exporting'),
        await import('highcharts/modules/export-data'),
    ])

    HighchartsBoost(Highcharts)
    HighchartsExporting(Highcharts)
    HighchartsExportData(Highcharts)

    Highcharts.setOptions({
        chart: {
            style: {
                fontFamily: 'Inter',
            },
        },
        xAxis: {
            labels: {
                style: {
                    letterSpacing: '-0.025em',
                    color: '#686868',
                },
            },
        },
        yAxis: {
            labels: {
                style: {
                    letterSpacing: '-0.025em',
                    color: '#686868',
                },
            },
            title: {
                style: {
                    letterSpacing: '-0.025em',
                    color: '#686868',
                },
            },
        },
    })

    // @ts-ignore
    Highcharts.Templating.helpers.abs = (value) => Math.abs(value)

    highcharts = Highcharts

    return Highcharts
}
