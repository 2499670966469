<template>
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 17.1562C13.5046 17.1562 17.1562 13.5046 17.1562 9C17.1562 4.49543 13.5046 0.84375 9 0.84375C4.49543 0.84375 0.84375 4.49543 0.84375 9C0.84375 13.5046 4.49543 17.1562 9 17.1562ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
            fill="currentColor" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.52903 11.721C7.40699 11.5989 7.40699 11.4011 7.52903 11.279L9.80806 9L7.52903 6.72097C7.40699 6.59893 7.40699 6.40107 7.52903 6.27903C7.65107 6.15699 7.84893 6.15699 7.97097 6.27903L10.471 8.77903C10.593 8.90107 10.593 9.09893 10.471 9.22097L7.97097 11.721C7.84893 11.843 7.65107 11.843 7.52903 11.721Z"
            fill="currentColor" />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
